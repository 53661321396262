/* eslint-disable no-mixed-operators */
'use strict';
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */

module.exports = {
    name$regex: /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Zàâçéèêëîïôûùüÿñæœáíóú]+(\s{0,1}[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Zàâçéèêëîïôûùüÿñæœáíóú, {2}])*$/i,
    // name$regex: /^[A-Za-z]+$/,
    email$regex: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,8}|[0-9]{1,3})(\]?)$/,

    quickButtonLoginEnable: function (elemForm, elemEmail, elemPassword, elemButton) {
        var $enoccode = $('.js-enoc-node-input').length > 0;
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if (($(elemEmail).val().length > 5 && $(elemEmail).valid()) && ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17)) {
               
                if ($enoccode) {
                    if ($('.js-enoc-node-input').val().length >= 3) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', true);
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                }
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }

            
        });
    },
    loginOTPEnableButton: function (elemForm, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(elemForm).valid()) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });
    },
    loginPartnerOTPEnableButton: function (elemForm, elemPhone, elemRecipt, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            if (
                ($(elemPhone).val().length > 5) &&
                ($(elemRecipt).val().length > 1)
            ) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    quickButtonInstantCashResent: function (elemForm, elemNumber, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if (($(elemNumber).val().length > 8)) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    quickButtonLoginInstantcashEnable: function (elemForm, elemEmail, elemPassword, elemConfirmPassword, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if (($(elemEmail).val().length > 5 && $(elemEmail).valid()) && ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) && ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid()) && $('#agreeTerms').prop('checked') === true) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                if (($(elemEmail).val().length > 5 && $(elemEmail).valid()) && ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) && ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid())) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    registerationButtonEnableMobile: function (elemForm, elemButton) {
        var $flagged = false;
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val() >= 2 && $('#agreeTerms').prop('checked') === true && $('.jq-dte-day').val() !== 'DD' && $('.jq-dte-year').val() !== 'YYYY' && $('.jq-dte-month').val() !== 'MM' && $('.jq-dte-year').val().length === 4 && $('#nationality').val() !== '') {
                    if ($(elemForm).valid()) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                    if ($(this).val() >= 2 && $('.jq-dte-day').val() !== 'DD' && $('.jq-dte-year').val() !== 'YYYY' && $('.jq-dte-month').val() !== 'MM' && $('.jq-dte-year').val().length === 4 && $('#nationality').val() !== '') {
                        if ($(elemForm).valid()) {
                            $(elemButton).prop('disabled', false);
                            $(elemButton).removeClass('is-disabled');
                        } else {
                            $(elemButton).prop('disabled', 'disabled');
                            $(elemButton).addClass('is-disabled');
                        }
                    }
                });
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        $('#nationality').on('change', function () {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val() >= 2 && $('#agreeTerms').prop('checked') === true && $('.jq-dte-day').val() !== 'DD' && $('.jq-dte-year').val() !== 'YYYY' && $('.jq-dte-month').val() !== 'MM' && $('.jq-dte-year').val().length === 4) {
                    if ($(elemForm).valid()) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });
    },
    registerationButtonEnableMobileOTP: function (elemForm, elemButton) {
        var $flagged = false;
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val() >= 2 && $('#agreeTerms').prop('checked') === true) {
                    if ($(elemForm).valid()) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                
                    if ($(elemForm).valid()) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                    
                });
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    partnerregisterationButtonEnableMobile: function (elemForm, elemFirstName, elemLastName, elemPhone, elemRecipt, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
           
            if (
                ($(elemFirstName).val().length >= 2 && $(elemLastName).val().length >= 2 && $(elemFirstName).valid() && $(elemLastName).valid()) &&
                ($(elemPhone).val().length > 5) &&
                ($(elemRecipt).val().length > 1) &&
                $('#agreeTerms').prop('checked') === true
            ) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                if (
                    ($(elemFirstName).val().length >= 2 && $(elemLastName).val().length >= 2 && $(elemFirstName).valid() && $(elemLastName).valid()) &&
                    ($(elemPhone).val().length > 5) &&
                    ($(elemRecipt).val().length > 1) 
                ) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    partnerregisterationButtonEnableMobile2: function (elemForm, elemFirstName, elemLastName, elemEmail, elemPhone, elemPassword, elemConfirmPassword, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if (
                ($(elemFirstName).val().length >= 2 && $(elemLastName).val().length >= 2 && $(elemFirstName).valid() && $(elemLastName).valid()) &&
                ($(elemEmail).val().length > 5 && $(elemEmail).valid()) &&
                ($(elemPhone).val().length > 5) &&
                ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) &&
                ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid()) &&
                $('#agreeTerms').prop('checked') === true
            ) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                if (
                    ($(elemFirstName).val().length >= 2 && $(elemLastName).val().length >= 2 && $(elemFirstName).valid() && $(elemLastName).valid()) &&
                    ($(elemEmail).val().length > 5 && $(elemEmail).valid()) &&
                    ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) &&
                    ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid()) &&
                    ($(elemPhone).val().length > 5)
                ) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    partnerregisterationClaimButtonEnableMobile: function (elemForm, elemPassword, elemConfirmPassword, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
           
            if (
                ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) &&
                ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid()) 
            ) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });

        // $(elemForm).on('change', '#agreeTerms', function () {
        //     if ($('#agreeTerms').prop('checked') === true) {
        //         if (
        //             ($(elemPassword).val().length >= 8 && $(elemPassword).val().length <= 17) &&
        //             ($(elemConfirmPassword).val().length >= 8 && $(elemConfirmPassword).val().length <= 17 && $(elemConfirmPassword).valid())
        //         ) {
        //             $(elemButton).prop('disabled', false);
        //             $(elemButton).removeClass('is-disabled');
        //         } else {
        //             $(elemButton).prop('disabled', 'disabled');
        //             $(elemButton).addClass('is-disabled');
        //         }
        //     } else {
        //         $(elemButton).prop('disabled', 'disabled');
        //         $(elemButton).addClass('is-disabled');
        //     }
        // });
    },
    registerationButtonEnableMobileQuickCheckout: function (elemForm, elemButton) {
        var $flagged = false;
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val() >= 3) {
                    if ($(elemForm).valid()) {
                        alert('yes');
                    } else {
                        alert('no');
                    }
                }
            });
        });
    },
    forgotPasswordbuttonEnable: function (elemForm, elemEmail, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden)', function () {
            if ($(elemEmail).val().length > 5 && $(elemEmail).valid()) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', true);
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    registerationButtonEnable: function (form, otp, mobileNumber, mobileBtn, terms, elemButton) {
        $(mobileNumber).on('keyup', function () {
            if ($(this).val().length > 4 && $(this).val().length < 13) {
                $(mobileBtn).prop('disabled', false);
                $(mobileBtn).removeClass('is-disabled');
                $(otp).val('');
            } else {
                $(mobileBtn).prop('disabled', true);
                $(mobileBtn).addClass('is-disabled');
                $(otp).val('');
                $(otp).removeClass('valid');
                $(otp).parent().addClass('d-none');
            }
        });
        $(otp).on('keyup', function (event) {
            if ($(this).val().length >= 4 && $(terms).is(':checked')) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', true);
                $(elemButton).addClass('is-disabled');
            }
        });
        $(terms).change(function () {
            if (this.checked && $(otp).val().length >= 4 && $(form).valid()) {
                $(elemButton).prop('disabled', false);
                $(elemButton).removeClass('is-disabled');
            } else {
                $(elemButton).prop('disabled', true);
                $(elemButton).addClass('is-disabled');
            }
        });
    },
    updateProfileEnableButton: function (form, firstname, lastname, mobilenumber, dateofbirth, button) {
        $(form).find('input').on('keyup', function () {
            $(form).valid();
            if (
                $(firstname).val().length >= 2 && $(lastname).val().length >= 2
            ) {
                $(button).removeClass('is-disabled');
            } else {
                $(button).addClass('is-disabled');
            }
        });
        $(form).find('select').on('change', function () {
            $(button).removeClass('is-disabled');
        });

        // $(document).on('birthChange', function (e, changeData) {
        //     if ($('#birthdayPrev').val() === changeData.data) {
        //         $(button).addClass('is-disabled');
        //     } else {
        //         // eslint-disable-next-line no-lonely-if
        //         if (
        //             $(firstname).val().length >= 2 && $(lastname).val().length >= 2 ||
        //             $(mobilenumber).val() !== $(mobilenumber).data('old-number') ||
        //             ($('.jq-dte-day').val().length === 2 && $('.jq-dte-month').val().length === 2 && $('.jq-dte-year').val().length === 4)
        //         ) {
        //             $(button).removeClass('is-disabled');
        //         } else {
        //             $(button).addClass('is-disabled');
        //         }
        //     }
        // })

        // $(dateofbirth).on('change', function () {
        //     console.log('date');
        //     console.log($(dateofbirth).val());
        //     if ($(this).val().length >= 8) {
        //         $(button).removeClass('is-disabled');
        //     } else {
        //         $(button).addClass('is-disabled');
        //     }
        // });


        $(document).on('radioChange', function () {
            $(button).removeClass('is-disabled');
        });

        // $('.js-profile-update').on('click', function () {
        //     console.log('clicked');
        //     $(form).submit();
        // });
    },
    updatePhoneEnableButton: function (form, mobilenumber, button) {
        $(form).find('input').on('keyup', function () {
            $(form).valid();
            if (
                $($(mobilenumber).val() !== $(mobilenumber).data('old-number'))
            ) {
                $(button).removeClass('is-disabled');
            } else {
                $(button).addClass('is-disabled');
            }
        });
    },
    changePasswordEnableButton: function (form, current, newpass, confirmpass, button) {
        $(form).find('input').on('keyup', function () {

            if (
                $(current).val().length >= 8 && $(newpass).val().length >= 8 && $(confirmpass).val().length >= 8 && $(current).val() !== $(newpass).val() && $(newpass).val() === $(confirmpass).val()
            ) {
                $(form).valid();
                $(button).removeClass('is-disabled');
            } else {
                $(button).addClass('is-disabled');
            }
        });
    },
    contactUsFormEnableButton: function (form, name, email, message, button) {
        $(form).find('input, textarea').on('keyup', function () {
            if ($(name).val().length >= 2 && $(name).valid() && $(email).val().length >= 5 && $(email).valid() && $(message).val().length >= 15) {
                $(form).valid();
                $(button).removeClass('is-disabled');
            } else {
                $(button).addClass('is-disabled');
            }
        });
    }
};
